import "../App.css";
import React from "react";
import { useHistory } from "react-router-dom";
import AdminMail from "../components/AdminMail";
import { isAuth, unAuth, getToken } from "../user/User";
import LogoutButton from "../components/LogoutButton";
import Username from "../components/Username";
import { Redirect } from "react-router-dom";
import getUserInfo from "../api/GetUserInfo";
import TaskRow from "../components/TaskRow";
import { useState, useEffect } from "react";
import getTaskList from "../api/GetTaskList";
import getCSV from "../api/GetCSV";
import { DataGrid } from "@mui/x-data-grid";
import toast, { Toaster } from "react-hot-toast";
import { Button } from "@mui/material";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import getVttFile from "../api/GetVttFile";
import getTextFile from "../api/GetTextFile";
import getMediaFile from "../api/GetMediaFile";
var intervalId;
function HomePage() {
  const shareLinkClicked = (task_id) => {
    navigator.clipboard.writeText(
      "https://transcriptions.dataforlearningmachines.com/shared?task_id=" +
        task_id
    );
    toast.success("Link copied to clipboard", { position: "bottom-center" });
    //navigator.clipboard.writeText(props.task.task_id);
    //toast.success("Task id copied to clipboard", { position: "bottom-center" });
  };


  const downloadVTTFile = async (task_id) => {
    
    let text = await getVttFile(task_id);
    if (text) {
      const element = document.createElement("a");
      const file = new Blob([text], { type: "text/plain" });
      element.href = URL.createObjectURL(file);
      element.download = task_id + ".vtt";
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
      toast.success("Vtt downloaded successfully", {
        position: "bottom-center",
      });
    } else {
      toast.error("Vtt could not be downloaded", {
        position: "bottom-center",
      });
    }
  };

  const downloadTextFile = async (task_id) => {
    
    let text = await getTextFile(task_id);
    if (text) {
      const element = document.createElement("a");
      const file = new Blob([text], { type: "text/plain" });
      element.href = URL.createObjectURL(file);
      element.download = task_id + ".txt";
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
      toast.success("Text downloaded successfully", {
        position: "bottom-center",
      });
    } else {
      toast.error("Text could not be downloaded", {
        position: "bottom-center",
      });
    }
  };

  const downloadMediaFile = async (task_id,task_name) => {
    getMediaFile(task_id, task_name);
  };

  const columns = [
    { field: "task_name", headerName: "Task Name", flex: 1 },
    {
      field: "date_time",
      headerName: "Creation Date",
      type: "date",
      flex: 1,
      renderCell: (params) => {
        let date = new Date(params.row.date_time);
        return (
          <div>
            {date.getDate() +
              "/" +
              (date.getMonth() + 1) +
              "/" +
              date.getFullYear() +
              " " +
              date.getHours() +
              ":" +
              date.getMinutes()}
          </div>
        );
      },
    },
    { field: "status", headerName: "Status", flex: 1 },

    {
      field: "actions",
      headerName: "Action 1",
      flex: 1,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <Button
          onClick={() => shareLinkClicked(params.row.task_id)}
          style={{ width: 120, fontSize: 15, alignSelf: "center" }}
        >
          share link
        </Button>
      ),
    },
    {
      field: "actions2",
      headerName: "Action 2",
      flex: 1,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <PopupState variant="popover" popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <Button
                style={{ width: 120, fontSize: 15, alignSelf: "center" }}
                {...bindTrigger(popupState)}
              >
                Download
              </Button>
              <Menu {...bindMenu(popupState)}>
                <MenuItem
                  onClick={() => {
                    downloadVTTFile(params.row.task_id)
                    popupState.close();
                  }}
                >
                  VTT File
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    downloadTextFile(params.row.task_id)
                    popupState.close();
                  }}
                >
                  Text File
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    downloadMediaFile(params.row.task_id,params.row.task_name)
                    popupState.close();
                  }}
                >
                  Media File
                </MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      ),
    },
  ];

  const history = useHistory();
  let [canMakeAssignments, setCanMakeAssignments] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    async function getCanMakeAssignments(token) {
      const userObj = await getUserInfo(token);
      return userObj.canMakeAssignments;
    }
    const token = getToken();
    getCanMakeAssignments(token).then((cma) => {
      setCanMakeAssignments(cma);
    });
  });

  const myDataClicked = async () => {
    if (isAuth()) {
      history.push("/list");
    } else logout();
  };

  /**
   * get the task list if logged in, otherwise logs out
   */
  const getTasks = async () => {
    let token = getToken();
    if (token) {
      let tasks = await getTaskList(token);
      console.log(tasks);
      if (tasks) {
        const myTasks = tasks.tasks;
        setTasks(myTasks);
        //const assignedTasks = tasks.assignedTasks;
        //setAssignedTasks(assignedTasks);
      }
    } else {
      unAuth();
      clearInterval(intervalId);
      intervalId = null;
      history.push("login");
    }
  };

  useEffect(() => {
    if (!loading) {
      getTasks();
      setLoading(true);
      if (!intervalId) {
        //calls getTasks every 30 secs
        intervalId = setInterval(getTasks, 30000);
      }
      setLoading(false);
    }
  }, []);

  const logout = () => {
    unAuth();
    history.push("login");
  };
  const uploadNewTaskClicked = async () => {
    if (isAuth()) {
      history.push("create");
    } else logout();
  };

  const handleAssignments = () => {
    history.push("/assign");
  };

  if (isAuth()) {
    return (
      <div className="App">
        <header className="App-header">
          <AdminMail></AdminMail>
          <Username></Username>
          <LogoutButton></LogoutButton>
          <h1 style={{ color: "#ffffff", fontSize: 75 }}>
            Automatic Transcriber
          </h1>
          <div
            style={{
              backgroundColor: "rgba(255,255,255,0.05)",
              padding: 20,
              borderRadius: 10,
              width: "30%",
            }}
          >
            {/* <button
            onClick={myDataClicked}
            style={{
              backgroundColor: "#20DF7F",
              width: "100%",
              height: 35,
              borderRadius: 7,
              marginTop: 10,
              color: "white",
            }}
          >
            My Media
          </button>
          <div style={{ height: 10 }}></div>*/}
            <button
              onClick={uploadNewTaskClicked}
              style={{
                backgroundColor: "#20DF7F",
                width: "100%",
                height: 35,
                borderRadius: 7,
                marginTop: 10,
                color: "white",
              }}
            >
              Upload Media
            </button>
            {/* canMakeAssignments && (<><div style={{ height: 10 }}></div><button
            onClick={handleAssignments}
            style={{
              backgroundColor: "#20DF7F",
              width: "100%",
              height: 35,
              borderRadius: 7,
              marginTop: 10,
              color: "white",
            }}
          >
            Assign tasks
          </button><div style={{ height: 10 }}></div></>)*/}
          </div>
          <div style={{ height: 20 }}></div>
          <div
            style={{
              backgroundColor: "rgba(255,255,255,0.05)",
              padding: 20,
              borderRadius: 10,
              width: "50%",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ flex: 1 }}>
                <button
                  onClick={async () => {
                    let text = await getCSV();
                    if (text) {
                      const element = document.createElement("a");
                      const file = new Blob([text], { type: "text/plain" });
                      element.href = URL.createObjectURL(file);
                      element.download = "YourLinks.csv";
                      document.body.appendChild(element); // Required for this to work in FireFox
                      element.click();
                    } else {
                      toast.error("links file could not be downloaded", {
                        position: "bottom-center",
                      });
                    }
                  }}
                >
                  Get all Links
                </button>
              </div>

              <p
                style={{ fontSize: 30, margin: 0, fontWeight: "bold", flex: 1 }}
              >
                Your Media
              </p>
              
              <div style={{flex: 1,  textAlign: "end" }}>
              <Button
              sx={{marginRight:10}}
                onClick={() => {
                  window.location.reload();
                }}
              >
                Reset Filters
              </Button>
            </div>
            </div>
            {/*tasks
              .slice(0)
              .reverse()
              .map((e, i) => {
                  return <TaskRow key={i} task={e}></TaskRow>;
              })*/}
            <div
              style={{
                height: window.innerHeight/1.8,
                width: "100%",
                backgroundColor: "rgba(255,255,255,0.7)",
                borderRadius: 20,
              }}
            >
              
              {
                <DataGrid
                  sx={{ borderWidth: 0 }}
                  Background="Transparent"
                  RowBackground="Transparent"
                  rows={tasks}
                  columns={columns}
                  getRowId={(row) => row.task_id}
                />
              }
            </div>
          </div>

          {/*<img
          src={require("../images/background.png")}
          style={{
            bottom: "0%",
            backgroundColor: "rgba(0,0,0,0)",
            width: "100%",
            position: "absolute",
          }}
        ></img>*/}
          <Toaster></Toaster>
        </header>
      </div>
    );
  } else {
    return <Redirect to="login"></Redirect>;
  }
}

export default HomePage;